.optimize-section {
  max-width: var(--container-max-width);
  margin: 0 auto;
  padding-bottom: 40px;
  border-bottom: 1px solid var(--border);

  .optimize-section-container {
    display: flex;
    flex-direction: row;
    gap: 1%;

    @media screen and (max-width: 699px) {
      flex-direction: column;
      gap: 30px;
    }
  }
}
