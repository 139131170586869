.home-section {
  padding: 120px 0px 120px 0px;
  @media (max-width: 992px) {
    padding: 100px 0px 100px 0px;
  }
  @media (max-width: 767px) {
    padding: 0px 0px 0px 0px;
  }
  max-width: var(--container-max-width);
  margin: 0 auto;
  .home-section-container {
    display: flex;
    flex-direction: row;

    @media (max-width: 699px) {
      flex-direction: column;
    }
    gap: 20px;

    .home-section-left {
      flex-basis: 50%;
      @media (max-width: 699px) {
        order: 2;
      }
      .home-section-texts {
        display: flex;
        flex-direction: column;
        gap: 32px;
      }

      .home-section-button {
        align-self: flex-start;
      }
    }
    .home-section-right {
      flex: 1;
      display: flex;
      flex-basis: 50%;
      @media (max-width: 699px) {
        order: 1;
      }
      .home-section-img {
        border-radius: 8px;
        flex: 1;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        @media (max-width: 699px) {
          height: 200px;
          width: 100%;
        }
      }
    }
  }
}
