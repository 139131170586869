.about-section {
  max-width: var(--container-max-width);
  margin: 0 auto;
  padding-bottom: 40px;
  border-bottom: 1px solid var(--border);
  .about-section-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    @media (max-width: 699px) {
      flex-direction: column;
    }

    .about-section-left {
      flex: 1;
      display: flex;
      flex-basis: 50%;

      .about-section-img {
        border-radius: 8px;
        flex: 1;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        @media (max-width: 699px) {
          height: 300px;
          width: 100%;
        }
      }
    }

    .about-section-right {
      flex-basis: 50%;

      .about-section-texts {
        display: flex;
        flex-direction: column;
        gap: 32px;
      }
    }
  }
}
