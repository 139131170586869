*,
:after,
:before {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
[class*="grid-"] {
  float: left;
  padding: 10px;
  width: 100%;
}
.clear-grid {
  clear: both;
  float: inherit;
}
.container {
  margin: 0;
  width: 100%;
}
.container:after,
.container:before {
  display: table;
  clear: both;
  content: " ";
}
.nest {
  margin: 0 -10px;
  padding: 0;
  width: auto;
}

.grid-xs-12 {
  width: 100%;
}
.grid-xs-11 {
  width: 91.666663%;
}
.grid-xs-10 {
  width: 83.33%;
}
.grid-xs-9 {
  width: 74.999997%;
}
.grid-xs-8 {
  width: 66.66666664%;
}
.grid-xs-7 {
  width: 58.333%;
}
.grid-xs-6 {
  width: 50%;
}
.grid-xs-5 {
  width: 41.6665%;
}
.grid-xs-4 {
  width: 33.33%;
}
.grid-xs-3 {
  width: 24.99%;
}
.grid-xs-2 {
  width: 16.66666%;
}
.grid-xs-1 {
  width: 8.33%;
}
@media (min-width: 576px) {
  .grid-sm-12 {
    width: 100%;
  }
  .grid-sm-11 {
    width: 91.666663%;
  }
  .grid-sm-10 {
    width: 83.33%;
  }
  .grid-sm-9 {
    width: 74.999997%;
  }
  .grid-sm-8 {
    width: 66.66666664%;
  }
  .grid-sm-7 {
    width: 58.333%;
  }
  .grid-sm-6 {
    width: 50%;
  }
  .grid-sm-5 {
    width: 41.6665%;
  }
  .grid-sm-4 {
    width: 33.33%;
  }
  .grid-sm-3 {
    width: 24.99%;
  }
  .grid-sm-2 {
    width: 16.66666%;
  }
  .grid-sm-1 {
    width: 8.33%;
  }
}
@media (min-width: 768px) {
  .grid-12 {
    width: 100%;
  }
  .grid-11 {
    width: 91.666663%;
  }
  .grid-10 {
    width: 83.33%;
  }
  .grid-9 {
    width: 74.999997%;
  }
  .grid-8 {
    width: 66.66666664%;
  }
  .grid-7 {
    width: 58.333%;
  }
  .grid-6 {
    width: 50%;
  }
  .grid-5 {
    width: 41.6665%;
  }
  .grid-4 {
    width: 33.33%;
  }
  .grid-3 {
    width: 24.99%;
  }
  .grid-2 {
    width: 16.66666%;
  }
  .grid-1 {
    width: 8.33%;
  }
  .grid-md-12 {
    width: 100%;
  }
  .grid-md-11 {
    width: 91.666663%;
  }
  .grid-md-10 {
    width: 83.33%;
  }
  .grid-md-9 {
    width: 74.999997%;
  }
  .grid-md-8 {
    width: 66.66666664%;
  }
  .grid-md-7 {
    width: 58.333%;
  }
  .grid-md-6 {
    width: 50%;
  }
  .grid-md-5 {
    width: 41.6665%;
  }
  .grid-md-4 {
    width: 33.33%;
  }
  .grid-md-3 {
    width: 24.99%;
  }
  .grid-md-2 {
    width: 16.66666%;
  }
  .grid-md-1 {
    width: 8.33%;
  }
}
@media only screen and (min-width: 992px) {
  .grid-lg-12 {
    width: 100%;
  }
  .grid-lg-11 {
    width: 91.666663%;
  }
  .grid-lg-10 {
    width: 83.33%;
  }
  .grid-lg-9 {
    width: 74.999997%;
  }
  .grid-lg-8 {
    width: 66.66666664%;
  }
  .grid-lg-7 {
    width: 58.333%;
  }
  .grid-lg-6 {
    width: 50%;
  }
  .grid-lg-5 {
    width: 41.6665%;
  }
  .grid-lg-4 {
    width: 33.33%;
  }
  .grid-lg-3 {
    width: 24.99%;
  }
  .grid-lg-2 {
    width: 16.66666%;
  }
  .grid-lg-1 {
    width: 8.33%;
  }
}
@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1180px;
    margin: 0 auto;
  }
  .container-fluid {
    max-width: 90%;
    margin: 0 5%;
  }
  .grid-xl-12 {
    width: 100%;
  }
  .grid-xl-11 {
    width: 91.666663%;
  }
  .grid-xl-10 {
    width: 83.33%;
  }
  .grid-xl-9 {
    width: 74.999997%;
  }
  .grid-xl-8 {
    width: 66.66666664%;
  }
  .grid-xl-7 {
    width: 58.333%;
  }
  .grid-xl-6 {
    width: 50%;
  }
  .grid-xl-5 {
    width: 41.6665%;
  }
  .grid-xl-4 {
    width: 33.33%;
  }
  .grid-xl-3 {
    width: 24.99%;
  }
  .grid-xl-2 {
    width: 16.66666%;
  }
  .grid-xl-1 {
    width: 8.33%;
  }
}
img.scale,
img.responsive {
  max-width: 100%;
  height: auto;
}

.height-100 {
  height: 100%;
}
