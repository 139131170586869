.contact-section {
  max-width: var(--container-max-width);
  margin: 0 auto;
  .input-contact {
    padding-bottom: 10px;
  }
  .contact-section-button {
    display: flex;
    justify-content: center;
    padding: 20px 0px;
  }
}
