.footer {
  .footer-container {
    display: flex;
    flex-direction: row;
    gap: 1%;
    border-top: 1px solid var(--border);
    max-width: 992px;
    margin: 0 auto;
    padding: 30px 0px;
    @media (max-width: 992px) {
      padding: 20px 0px;
      margin: 0 20px;
    }

    @media screen and (max-width: 699px) {
      flex-direction: column;
      gap: 20px;
      align-items: center;
      text-align: center;
    }

    .footer-container-main-cell {
      flex-basis: 40%;
    }
    .footer-container-cell {
      flex-basis: 30%;
      .footer-link {
        font-weight: 500;
        font-size: 14px;
      }
    }
  }

  .footer-copyright {
    padding: 14px;
    margin: 0 auto;
    background-color: var(--blue);
    text-align: center;
    h3 {
      font-size: 16px;
      color: var(--white);
    }
  }
}
