.formation-cell {
  flex-basis: 24.25%;
  border: 1px solid var(--border);
  border-radius: 8px;
  overflow: hidden;
  .formation-cell-image {
    width: 100%;
    height: 160px;
    object-fit: cover;
  }

  .formation-cell-texts {
    padding: 16px;
    text-align: left;
    display: flex;
    gap: 10px;
    flex-direction: column;

    .formation-cell-button {
      align-self: flex-start;
    }
  }
}
