.formation {
  max-width: 780px;
  margin: 0px auto;
  text-align: left;
  padding-bottom: 60px;

  h2 {
    padding: 0px 0px 10px 0px;
    text-align: left;
    font-weight: 800;
    font-size: 20px;
    color: var(--blue);
  }
  p {
    padding: 0px 0px 5px 0px;
  }
  ul {
    padding: 0px 0px 10px 20px;
  }
  h3 {
    padding: 0px 0px 10px 0px;
    color: var(--green);
  }
}
