.app-wrapper {
  padding-top: 92.5px;
  @media (max-width: 1032px) {
    padding: 92.5px 20px 0px 20px;
  }

  @media (max-width: 699px) {
    padding: 62px 20px 0px 20px;
  }
}
