.ui-input {
  display: flex;
  flex-direction: column;
  gap: 2px;

  .ui-input-label {
    font-weight: 800;
    font-size: 14px;
    color: var(--text);
  }

  .ui-input-input {
    padding: 10px;
    height: 48px;
    border: 1px solid var(--border);
    border-radius: 6px;
    font-family: "Nunito";
    font-size: 16px;
  }

  .ui-input-text-area {
    padding: 10px;
    border: 1px solid var(--border);
    border-radius: 6px;
    font-family: "Nunito";
    font-size: 16px;
    resize: vertical;
  }
}
