.topbar {
  height: 92.5px;
  position: fixed;
  margin-bottom: 0;
  z-index: 10;
  border: 0;
  top: 0px;
  left: 0px;
  right: 0px;
  background: var(--background-nav);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  transition: height 0.25s;

  @media screen and (max-width: 760px) {
    height: 62px;
  }

  .topbar-container {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 24px auto;
    max-width: var(--container-max-width);

    @media screen and (max-width: 1032px) {
      padding: 0 20px;
    }

    @media screen and (max-width: 760px) {
      padding: 0 20px;
      margin: 12px auto;
    }

    .topbar-brand {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      .topbar-brand-name {
        span {
          color: var(--blue);
        }
        font-weight: 800;
        font-size: 22px;
      }
    }

    .topbar-links {
      display: flex;
      flex-direction: row;
      gap: 8px;

      @media screen and (max-width: 760px) {
        display: none;
      }

      .topbar-link-container {
        align-self: center;
        padding: 6px 10px;
        cursor: pointer;
        border-radius: 6px;
        &:hover {
          padding: 5px 9px;
          color: var(--blue);
          border: 1px var(--blue) solid;
          background-color: var(--blue-a1);
        }
      }
      .topbar-link-container--active {
        padding: 5px 9px;
        color: var(--blue);
        border: 1px var(--blue) solid;
        background-color: var(--blue-a1);
      }
    }

    .topbar-buttons-mobile {
      height: 34px;
      width: 34px;
      border: 1px solid var(--text);
      border-radius: 4px;
      display: flex;
      flex-direction: row;
      gap: 8px;
      justify-content: center;
      align-items: center;
      transition: 0.25s;
      &:hover {
        background-color: var(--blue-a1);
        color: var(--blue);
        border-color: var(--blue);
      }
      @media screen and (min-width: 761px) {
        display: none;
      }
    }

    .topbar-dropdown-indicator {
      font-size: 20px;
      padding: 0px 10px;
      cursor: pointer;
    }
  }

  .topbar-links-mobile {
    display: flex;
    flex-direction: column;
  }

  .topbar-link {
    font-size: 14px;
    font-weight: 700;

    @media screen and (max-width: 760px) {
      font-size: 16px;
      padding: 10px 14px;
      cursor: pointer;
      &:hover {
        font-weight: 900;
      }
    }
  }
  .dropdown-container {
    background: var(--background);
    -webkit-box-shadow: 0px 18px 12px 0px #8b8b8b59;
    -moz-box-shadow: 0px 18px 12px 0px #8b8b8b59;
    box-shadow: 0px 18px 12px 0px #8b8b8b59;
    z-index: 6;
    padding: 0 10px;
  }
}
