.value-cell {
  flex-basis: 33%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 20px;
  .value-cell-icon-container {
    background-color: var(--green-a1);
    border-radius: 8px;
    display: flex;
    width: 48px;
    height: 48px;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    font-size: 22px;
  }
}
