.ui-button {
  display: inline-block;
  background: var(--blue);
  text-align: center;
  font-family: "Nunito";
  font-weight: 700;
  font-size: 15px;
  transition: 0.25s;
  color: var(--white);
  padding: 12px 16px;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;

  &:hover {
    opacity: 0.8;
  }

  &-disabled {
    cursor: not-allowed;
    opacity: 0.5;
    text-decoration: none;
    pointer-events: none;
  }
}

@mixin ui-button-theme($color) {
  background: var($color);
  color: var(--white);
}

.ui-button-green {
  @include ui-button-theme(--green);
}
.ui-button-yellow {
  @include ui-button-theme(--yellow);
}
.ui-button-blue {
  @include ui-button-theme(--blue);
}
.ui-button-dark_blue {
  @include ui-button-theme(--dark_blue);
}
.ui-button-red {
  @include ui-button-theme(--red);
}

@mixin ui-button-theme-outline($color) {
  border: 1px var($color) solid;
  color: var($color);
  background: none;
}

.ui-button-green-outline {
  @include ui-button-theme-outline(--green);
}
.ui-button-yellow-outline {
  @include ui-button-theme-outline(--yellow);
}
.ui-button-blue-outline {
  @include ui-button-theme-outline(--blue);
}
.ui-button-dark_blue-outline {
  @include ui-button-theme-outline(--dark_blue);
}
.ui-button-red-outline {
  @include ui-button-theme-outline(--red);
}
