@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700;800;900&display=swap");

html {
  //Texts
  --text: #00171f;
  --subtitle: #697d84;
  --background: #ffffff;
  --card: #f5f6fa;
  --background-nav: rgba(255, 255, 255, 0.8);
  --border: #e1e1e1;
  --blue: #006dff;
  --green: #088517;
  --red: #d93a47;
  --dark_blue: #00171f;
  --white: #ffffff;
  --blue-a1: rgba(0, 109, 255, 0.1);
  --green-a1: rgba(46, 233, 67, 0.1);
  --red-a1: rgba(235, 31, 47, 0.1);
  --dark_blue-a1: rgba(0, 23, 31, 0.1);
  --white-a1: rgba(255, 255, 255, 0.1);

  --container-max-width: 992px;
}

body {
  margin: 0;
  font-family: "Nunito", Verdana, Geneva, Tahoma, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background);
  color: var(--text);
}

h2,
h3,
h4,
h5,
h6,
p {
  color: var(--text);
  margin: 0px;
}

h1 {
  font-family: "Nunito", Verdana, Geneva, Tahoma, serif;
  font-weight: 800;
  font-size: 44px;
  line-height: 56px;
  @media (max-width: 576px) {
    font-size: 38px;
    line-height: 46px;
  }
  span {
    color: var(--blue);
  }
}
h2 {
  padding: 40px 0px;
  text-align: center;
  font-weight: 800;
  font-size: 32px;
}
h3 {
  font-weight: 800;
  font-size: 18px;
}
h4 {
  font-weight: 800;
  font-size: 15px;
  line-height: 18px;
}

h5 {
  font-weight: 700;
  font-size: 16px;
  color: var(--blue);
}

h6 {
  font-weight: 400;
  font-size: 12px;
}

p {
  font-weight: 500;
  font-size: 14px;
}

a {
  font-weight: 300;
  font-size: 12px;
  color: var(--blue);
  text-decoration: none;
}

.formation-cells-container {
  display: flex;
  flex-direction: row;
  row-gap: 10px;
  column-gap: 1%;
  padding-top: 40px;
  flex-wrap: wrap;
  @media screen and (max-width: 699px) {
    flex-direction: column;
    gap: 30px;
    padding-top: 20px;
  }
}

.img-responsive {
  max-width: 100%;
  height: auto;
}
